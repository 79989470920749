.custom-button {
    background-color: #789447 !important;
    border-color: #789447 !important;
    font-weight: bold !important;
}

.custom-button:hover {
    background-color: #1e73be !important;
    border-color: #1e73be !important;
}
